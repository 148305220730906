<template>
<div>


  <!-- Course Title Block-->
  <v-sheet class="d-flex" :color="wsLIGHTCARD">
    <v-sheet max-width="1240" width="100%" color="transparent" class="px-2 mx-auto mb-15"    >
      <portal-target name="noImageCourse"></portal-target>

      <v-row style="overflow-y: hidden" no-gutters>
        <v-col :cols="SM ? 12 : 7"   >
          <portal :disabled="entity.img" to="noImageCourse">

            <router-link :to="businessPublicLink('')" class="noUnderline">
              <v-sheet
                  style="border-radius: 50%"
                  class="d-flex align-center justify-center my-8"
                  width="62" height="64" :style="`border : 1px solid ${wsACCENT} !important`" color="transparent">
                <v-icon :color="wsACCENT">mdi-arrow-left</v-icon>
              </v-sheet>
            </router-link>
            <h2 :style="`color : ${wsACCENT}; font-size: ${!SM ? 19 : 19}px`" class="mb-5"
            >
              {{ $store.state.business.selectedBusiness.name }}
            </h2>

            <div
                v-if="entity.img && SM"
                style="width : 100%; height: 100%;"
                 class="d-flex justify-center mb-8" >
              <v-responsive aspect-ratio="1.3">
                <img
                    :src="entity.img"
                    height="100%"
                    width="100%"
                    style="border-radius: 25px; margin-left: auto; margin-right: auto; object-fit: cover"

                />
              </v-responsive>

            </div>

            <v-divider :style="`border-color : ${wsBACKGROUND}`" />

          </portal>

          <h1 :style="`color : ${wsDARKER};font-size: ${!SM ? 42 : 34}px`" class="mt-10">
            {{ entity.name }}
          </h1>
          <h3
              v-if="entity.img"
              :style="`color : ${wsDARKER}`"
              class="font-weight-regular mt-5"
              style="font-size: 18px"
          >
            {{  entity.short_description  }}
          </h3>
          <view-button
              class="mt-5"
              v-if="entity.img && entity.uuid"
              :element="button"
              block-style="light_1"
              padding-x="20"
              padding-y="8"
              :block="block"
              flex
          />
        </v-col>
        <v-col cols="12" sm="5"  >

          <div v-if="!entity.img">
            <h3
                :style="`color : ${wsDARKER}`"
                class="font-weight-regular mt-14"
                style="font-size: 18px"
            >
              {{  entity.short_description  }}
            </h3>
            <view-button
                class="mt-5"
                v-if="entity.uuid"
                :element="button"
                block-style="light_1"
                padding-x="20"
                padding-y="8"
                :block="block"
                flex
            />

          </div>
          <div v-else-if="!SM" class="d-flex justify-end ">
            <img
                :src="entity.img"
                style="border-radius: 50%; margin-left: auto; margin-top : -32px; object-fit: cover"
                height="472"
                width="472"
            />
          </div>


        </v-col>

      </v-row>


    </v-sheet>

  </v-sheet>

  <!-- Course Content -->
  <v-sheet class="d-flex" >
    <v-sheet width="1240" color="transparent" class="px-2 mx-auto "  style="margin-top: 75px; margin-bottom: 75px"  >
      <h1 :style="`color : ${wsDARKER}`">{{ $t('CourseContent') }}</h1>
      <view-course-content
          class="mt-8"
          v-if="entity.uuid"
      />
    </v-sheet>
  </v-sheet>

  <!-- Block Course Pricing -->
  <v-sheet
      v-if="entity.has_payment_plans"
      :color="wsLIGHTCARD"
      class="d-flex" >
    <v-sheet width="1240" color="transparent" class="px-2 mx-auto "  style="margin-top: 75px;"  >
      <h1 :style="`color : ${wsDARKER}`">{{ $t('PaymentPlans') }}</h1>
    </v-sheet>
  </v-sheet>
  <view-block
      v-if="entity.has_payment_plans"
      :block="blockCourses"
      default-site
  />

  <!-- Contact Form -->
  <v-sheet :class="[{'d-flex' : !SM}]" class="justify-center" :color="wsBACKGROUND" >
    <v-sheet max-width="1240" width="100%" color="transparent" class="px-2 d-flex align-center justify-center"
             style="padding-top: 75px; padding-bottom: 75px"
    >
      <div>
        <h1 class="text-center"   :style="!SM ? 'width : 400px' : null">
          {{ $t("avalon.default.contact_form_title") }}
        </h1>
        <viewForm
            class="mt-8"
            :style="!SM ? 'width : 400px' : null"
            block-style="light_1"
            :block="{ config : { style : 'light_1' } } "
        />

      </div>





    </v-sheet>
  </v-sheet>



</div>
</template>

<script>
import {mapActions} from "vuex";
import viewCourseContent from "@/components/AvalonEditor/View/elements/viewCourseContent";
import viewForm from "@/components/AvalonEditor/View/elements/viewForm";
import viewButton from "@/components/AvalonEditor/View/elements/button/viewButton";
import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock";

export default {
  name: "DefaultSiteHome",
  components : {
    viewCourseContent,
    viewForm,
    viewButton,
    viewBlock
  },
  props : {
    course : {
      type : String
    }
  },
  data() {
    return {
      entity : {},
      blockCourses: {
        type: 'course-pricing',
        config: {
          "style": "light_2",
          "width": 1240,
          "design": "list_small",
          "gutter": 40,
          "pagination": true,
          "padding_top": 40,
          "padding_top_sm": 40,
          "block_radius": 4,
          "color_styles": [],
          "fonts_styles": [],
          "image_height": 200,
          "columns_large": 3,
          "display_images": true,
          "items_per_page": 3,
          "padding_bottom": 75,
          "padding_bottom_sm": 40,
          "gutter_vertical": 32,
          "display_category": true,
          "visibility_mobile": true,
          "display_categories": true,
          "visibility_desktop": true,
          "display_description": true
        },
      },
      button : {
        config : {
          action : 'enroll_on_course',
          style: 'primary',
          rounded : 4
        }
      },
      block : {
        config : {
          style : 'light_1'
        }
      }
    }
  },
  methods : {
    ...mapActions('courses', [
      'GET_COURSE_PUBLIC' ,
      'CHECK_STUDENT_ENROLLMENT',
      'GET_COURSE_FULL',
      'ENROLL_ON_COURSE' ,
      'GET_ENROLLMENT_WAVES_STUDENT'
    ]),


    async initPage(){
      if ( !this.loggedIn) {
        this.entity = await this.GET_COURSE_PUBLIC(this.course) || this.entityDataDefault
      } else {
        this.entity = await this.GET_COURSE_FULL(this.course) || this.entityDataDefault
      }
      this.$store.state.avalonPage.course = this.COPY(this.entity)
    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>