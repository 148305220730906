<template>

    <v-sheet
        class="fill-height d-flex align-baseline justify-center"
        :style="elementStyle"
        :color="!element.config.outlined ? GET_AVALON_COLOR(element.config.background_color) : 'transparent'"
    >
      <div
          :style="element.config.is_button ? `height : 100%` : null"
          style="width: 100%" v-if="element.config.type === 'lead' ">
        <div v-if="!element.config.is_button">
            <avalon-text-field
                v-if="element.config.fields.includes('name')"
                v-model="entityData.name"
                :label="element.config.has_labels ? $t('HumanName') : null"
                :placeholder="$t('EnterHumanName')"
                :block="element"
                :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
                :config-style="block.config.style"

            />

            <avalon-phone-field
                v-if="element.config.fields.includes('phone')"
                v-model="entityData.phone"
                :label="element.config.has_labels ? $t('Phone') : null"
                :placeholder="$t('Phone')" hide-details
                :error="phoneError"
                @input="phoneError = false"
                :block="element"
                :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
                :config-style="block.config.style"
            />

            <avalon-text-field
                v-if="element.config.fields.includes('email')"
                v-model="entityData.email"
                :label="element.config.has_labels ? $t('Email') : null"
                :placeholder="$t('Email')"
                :block="element"
                :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
                :config-style="block.config.style"
                email
            />

            <avalon-text-field
                v-if="element.config.fields.includes('comment')"
                v-model="entityData.comment"
                :label="element.config.has_labels ? $t('Comment') : null"
                :placeholder="$t('Comment')"
                :block="element"
                text-area
                :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
                :config-style="block.config.style"
                rows="3"

            />

          </div>

        <avalon-button
            @click="buttonAction"
            :class="[{'mt-5' : !element.config.is_button}]"
            :block-style="blockStyle"
            :block="element"
            :button-style="element.config.button_style"
            :radius="element.config.button_rounded"
            :text="element.config.button_text || $t('Send')"
            :save-text="$t('Send')"
            fill-area
        />



      </div>

      <avalon-dialog
          v-if="displayDialog"
          portal
          @save="sendForm"
          v-model="displayDialog"
          :title="element.config.dialog_title || $t('avalon.form.dialog_title')"
          :save-text="$t('Send')"
          avalon
          :blockStyle="blockStyle"
          :block="block"
      >

        <avalon-text-field
            v-if="element.config.fields.includes('name')"
            v-model="entityData.name"
            :label="element.config.has_labels ? $t('HumanName') : null"
            :placeholder="$t('EnterHumanName')"
            :block="block"
            :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"

        />
        <avalon-phone-field
            v-if="element.config.fields.includes('phone')"
            v-model="entityData.phone"
            :label="element.config.has_labels ? $t('Phone') : null"
            :placeholder="$t('Phone')" hide-details
            :error="phoneError"
            @input="phoneError = false"
            :block="block"
            :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
        />
        <avalon-text-field
            v-if="element.config.fields.includes('email')"
            v-model="entityData.email"
            :label="element.config.has_labels ? $t('Email') : null"
            :placeholder="$t('Email')"
            :block="block"
            :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
            email

        />
        <avalon-text-field
            v-if="element.config.fields.includes('comment')"
            v-model="entityData.comment"
            :label="$t('Comment')"
            :placeholder="$t('Comment')"
            :block="block"
            text-area
            :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
            rows="3"

        />

      </avalon-dialog>

    </v-sheet>

</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "viewButton",
  components : {},
  props : {
    element : {
      type : Object,
      default() {
        return {
          type : 'form',
          config : {
            background_color : 'transparent',
            type : 'lead',
            has_labels : true,
            fields : ['name' , 'phone', 'email' , 'comment'],
            fields_padding : 16
          }
        }
      }
    },
    blockStyle : {
      type : String
    },
    block : {
      type : Object,
      default() { return { config : {} } }
    }
  },
  data() {
    return {
      entityData : {},
      phoneError : false,
      nameError : false,
      displayDialog : false,
    }
  },
  computed : {

    buttonStyle() {
      let style = ''

      if (this.element.config.outlined) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.button_color)} !important;`
      }
      if (this.element.config.button_rounded) {
        style += `border-radius : ${this.element.config.button_rounded}px;`
      }

      if (this.element.config.button_margin_top) {
        style += `margin-top : ${this.element.config.button_margin_top}px;`
      }

      if (this.element.config.button_margin_left) {
        style += `margin-left : ${this.element.config.button_margin_left}px;`
      }

      if (this.element.config.button_margin_right) {
        style += `margin-right : ${this.element.config.button_margin_right}px;`
      }


      return style
    },
    elementStyle() {
      let style = ''

      if (this.element.config.outlined) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important;`
      }
      if (this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      return style
    }

  },
  methods : {
    ...mapActions('leads', ['ADD_BUSINESS_LEAD']),


    buttonAction() {
      if ( this.element.config.is_button ) {
        this.displayDialog = true
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      if ( this.element.config.type === 'lead') {
        this.addLead()
      }
    },
    async addLead() {
      if ( this.$store.state.avalon.editorMode ) {
        this.notify(this.$t('avalon.links_disabled_in_editor') , 'warning')
        return
      }
      if ( !this.entityData.phone || this.entityData.phone.length < 5 ) {
        this.phoneError = true
        return this.notify(this.$t('PhoneNumberEmpty') , 'warning')
      }
      if ( !this.entityData.name ) {
        this.nameError = true
        return this.notify(this.$t('NameEmpty') , 'warning')
      }
      this.entityData.origin = this.element.config.name || 'contact_form'
      this.entityData.device = this.DEVICE
      let result = await this.ADD_BUSINESS_LEAD(this.entityData)
      if( !result ) { return }
      this.notify(this.element.config.completion_text || this.$t('BusinessLeadCreated') , 'success')
      this.entityData = {
        phone : '+380'
      }
      this.displayDialog = false
    },
  },
  mounted() {
    if ( !this.element.config ) {
      this.element.config = {}
    }
  }
}
</script>

<style scoped>

</style>