<template>

  <div :class="[{'d-flex' : flex} , {'fill-height' : !flex}]">
    <ws-link
        :to="['page', 'dashboard'].includes(element.config.action) ? routerPath : null"
        :href="element.config.action === 'link' ? element.config.url : null"
        :disabled="$store.state.avalon.editorMode || $store.state.avalon.sideMenu"
        :new-window="element.config.new_window"
    >

      <v-sheet
          @click="action"
          class="fill-height pointer d-flex align-center"
          :class="[
                { 'justify-center' : !element.config.display_icon && element.config.style !== 'text' },
                { 'justify-space-between' : element.config.display_icon && element.config.style !== 'text' }
          ]"
          :style="elementStyle"
          :color="buttonBackgroundColor"
      >


      <h5 :style="buttonFontStyle">
          <span v-if="targetCourse.enrolled && !$store.state.avalon.editorMode">
            {{  $t('GotoCourse') }}
          </span>
        <span v-else-if="element.config.action === 'enroll_on_course' && !element.text">
            {{ courseText }}
          </span>
        <span v-else>
           {{ element.text || $t('ButtonText') }}
        </span>
      </h5>
      <v-icon class="ml-2" :color="BLOCK_COLOR_PARAM(element, colorVariable , blockStyle )" v-if="element.config.display_icon">
        {{ element.config.icon }}
      </v-icon>
      <viewButtonCourseEnroll
          @course-text="courseText = $event"
          v-if="element.config.action === 'enroll_on_course'"
          :display="displayCourseEnroll"
          :entity="targetCourse"
          avalon
          :element-uuid="element.id + 'button'"
          :block="block"
      />

      </v-sheet>

    </ws-link>

    <slider-sidebar
        v-if="displaySidebar"
        v-model="displaySidebar"
        :item="sidebarData"
        :block-style="blockStyle"
        :popup="displayDialog"
    />

    <avalon-dialog
        v-if="displayDialog"
        v-model="displayDialog"
        :title="sidebarData.title"
        :block-style="blockStyle"
        :block="element"
        hide-buttons
    >
      <div :id="`slider_sidebar_${element.id}`"  v-html="sidebarData.additional_text"></div>
    </avalon-dialog>

  </div>



</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import viewButtonCourseEnroll from "@/components/AvalonEditor/View/elements/button/viewButtonCourseEnroll";
import sliderSidebar from "@/components/AvalonEditor/Editor/blocks/slider/sliderSidebar";
export default {
  name: "viewButton",
  components : {
    viewButtonCourseEnroll,
    sliderSidebar
  },
  props : {
    element : {
      type : Object,
      default() { return {} }
    },
    block : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String
    },
    paddingX : {},
    paddingY : {},
    flex : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      targetCourse : {},
      displayCourseEnroll : 0,
      displaySidebar : false,
      displayDialog : false,
      courseText : '',
      buttonFontStyle : ''
    }
  },
  computed : {
    ...mapState('auth', [  'loggedIn'  ]),
    ...mapState('avalonPage',['course']),

    colorVariable() {
      return this.element.config.style === 'primary'
          ? 'button_text'
          : 'button_text_secondary'
    },
    sidebarData() {
      return {
        title : this.element.config.sidebar_title,
        text : this.element.config.sidebar_text,
        img : this.element.config.sidebar_img,
        additional_text : this.element.config.sidebar_additional_text
      }
    },
    buttonBackgroundColor() {
      if ( this.blockStyle && this.element.config.style) {
        if ( this.element.config.style === 'primary') {
          return this.BLOCK_COLOR_PARAM(this.element, 'button_bg' , this.blockStyle )
        } else {
          return 'transparent'
        }
      }
      return !this.element.config.outlined ? this.GET_AVALON_COLOR(this.element.config.background_color) : 'transparent'
    },
    buttonTextColor() {

      if ( this.blockStyle && this.element.config.style) {
        return this.CONFIG_PARAM_COLOR(
            'styles' ,
            this.blockStyle ,
            this.element.config.style === 'primary'
                ? 'button_text'
                : 'button_text_secondary'
        )
      }
      return this.element.config.text_color
    },

    routerTag() {
      if ( this.element.config.action === 'link' ) {
        return 'a'
      }
      if ( this.element.config.action === 'page' || this.element.config.action === 'dashboard' ) {
        return 'router-link'
      }
      return 'span'

    },
    routerPath() {
      if ( this.element.config.action === 'dashboard' ) {
        return this.businessDashLink('')
      }
      return this.businessPublicLink(this.element.config.page)
    },
    elementStyle() {
      let style = ''

      if (this.element.config.outlined || this.element.config.style === 'secondary' ) {

        let color = this.blockStyle && this.element.config.style
            ? this.BLOCK_COLOR_PARAM(this.element , 'button_bg_secondary' , this.blockStyle )
            : this.GET_AVALON_COLOR(this.element.config.background_color)

        style += `border : 2px solid ${color} !important;`
      }
      if (this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      if ( this.element.config.display_icon && this.element.config.style !== 'text' ) {
        let paddingHorizontal = 8;

        if ( this.element.config.rounded && this.element.config.rounded > 8 ) {
          paddingHorizontal = this.element.config.rounded
        }

        style += `padding-left  : ${paddingHorizontal}px;`
        style += `padding-right : ${paddingHorizontal}px;`

      }

      if ( this.paddingX ) {
        style += `padding-left : ${this.paddingX}px; padding-right : ${this.paddingX}px;`
      }
      if ( this.paddingY ) {
        style += `padding-top : ${this.paddingY}px; padding-bottom : ${this.paddingY}px;`
      }

      return style
    }

  },
  watch : {
    course() {
      this.targetCourse = this.course
    },
    loggedIn() {
      this.getCourse()
    },
    SCALE_COEFFICIENT() {
      this.getButtonFontStyle()
      setTimeout(()=>{
        this.getButtonFontStyle()
      },100)
    },
  },
  methods : {
    ...mapActions('courses', [  'GET_COURSE_BUTTON_PUBLIC' , 'GET_COURSE_BUTTON'  ]),
    ...mapMutations(['toggleLoginMenu']),

    getButtonFontStyle() {
      this.buttonFontStyle = this.BLOCK_TEXT_CSS(this.element , this.blockStyle , 'buttons' , 'text', this.colorVariable , false, this.block.config.width)
    },
    action() {
      if ( this.$store.state.avalon.editorMode && !['sidebar','dialog','enroll_on_course'].includes(this.element.config.action) ) {
        this.notify(this.$t('avalon.links_disabled_in_editor') , 'warning')
        return
      }
      let result = {
        enroll_on_course : this.enrollOnCourse,
        auth : this.toggleLoginMenu,
        sidebar : () => {  this.displaySidebar = true },
        dialog : () => {
          this.displayDialog = true
          setTimeout(()=> {
            this.PROCESS_BLOCK_TEXT_STYLES(`slider_sidebar_${this.element.id}`  , this.blockStyle ,this.element, null)
          },5)
        }
      }
      if ( !this.element.config ) {
        return
      }
      if ( !this.element.config.action ) {
        return
      }
      if ( ['dashboard','page','link'].includes(this.element.config.action) ) {
        return
      }
      result[this.element.config.action]()
    },
    async enrollOnCourse() {
      if ( !this.targetCourse.uuid ) {
        await this.getCourse()
      }
      this.displayCourseEnroll++
    },
    async getCourse(){

      if ( !this.element.config ) {
        return
      }
      if ( !this.element.config.action ) {
        return
      }

      if ( this.element.config.action !== 'enroll_on_course' ) {
        return
      }

      if ( !this.element.config.course && this.$store.state.avalonPage.course ) {
        this.targetCourse = this.COPY(this.$store.state.avalonPage.course)
        return
      }

      if ( !this.loggedIn) {
        this.targetCourse = await this.GET_COURSE_BUTTON_PUBLIC(this.element.config.course) || {}
      } else {
        this.targetCourse = await this.GET_COURSE_BUTTON(this.element.config.course) || {}
      }
    },
  },
  beforeMount() {
    this.getButtonFontStyle()
  },
  mounted() {
    this.getButtonFontStyle()
    if ( !this.element.config ) {
      this.element.config = {}
    }
    if ( this.course.uuid ) {
      this.targetCourse = this.course
    } else {
      this.getCourse()
    }
  }
}
</script>

<style scoped>

</style>